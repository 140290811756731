import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { PaymentTokenSymbol } from '@src/contracts/index';
import { useAppSelector, useContract, useTokenApproval } from '@src/hooks';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { parseBalance } from '@src/utils/web3';

import { useProjectContext } from '../context';

const ERROR_MESSAGE_MAP = {
    'execution reverted': 'The event is not ready yet',
};

export const InvestButtons: React.FC<{
    invest_amount: string;
    more_than_allocated: boolean;
    more_than_available: boolean;
    exceeds_cap?: boolean;
}> = ({
    invest_amount = '0',
    more_than_allocated,
    more_than_available,
    exceeds_cap,
}) => {
    const { account, connect } = useWeb3Onboard();
    const [loading, setLoading] = useState(false);
    const gt_zero = BigNumber.from(invest_amount).gt(0);

    const { user } = useAppSelector((state) => state?.auth) || {};

    const {
        event,
        updateCrowdfundingDetails,
        setInvestAmount,
        crowdfunding: { vesting_address, refund_fee } = {
            vesting_address: '',
            refund_fee: '0',
        },
        user_allocation_wei,
        proof,
        ga_registered,
    } = useProjectContext();

    const event_chain_id = event.chainId || DEFAULT_CHAIN_ID;

    const { address: payment_token, decimals } =
        CONTRACT.PaymentToken[event_chain_id];

    const {
        approved,
        handleApprove,
        loading: approve_loading,
    } = useTokenApproval(
        payment_token,
        account,
        vesting_address,
        invest_amount,
        event_chain_id,
    );
    const { abi = '[]', address } = event?.contract || {};
    const ABI = useMemo(
        () =>
            JSON.parse(abi || '[]').filter(
                ({ name }) => name !== 'Initialized',
            ),
        [abi],
    );

    const { isConnectedChainEventChain, setChainID, settingChain } =
        useSwitchChain();

    const is_connected_verified_wallet =
        user?.wallet_address?.toLowerCase() === account?.toLowerCase();

    const crowdfunding = useContract(address || '', ABI, true);

    const handleInvest = async () => {
        setLoading(true);
        toast.info('Awaiting successful transaction');

        try {
            const estimate = await crowdfunding.estimateGas.fundAgreement(
                invest_amount,
                user_allocation_wei,
                refund_fee,
                ga_registered,
                proof,
                {
                    gasLimit: 5_000_000,
                },
            );
            const invest_tx = await crowdfunding.fundAgreement(
                invest_amount,
                user_allocation_wei,
                refund_fee,
                ga_registered,
                proof,
                {
                    gasLimit: estimate.mul(6).div(5), // increase by 20 %
                },
            );

            const result = await invest_tx.wait();

            if (result.status === 0) {
                throw new Error('Transaction failed');
            }

            setInvestAmount('0');
            updateCrowdfundingDetails();
            toast.success(
                `Successfully invested ${parseBalance(
                    invest_amount,
                    decimals,
                )} ${PaymentTokenSymbol(event_chain_id)} in ${name}`,
            );
        } catch (err) {
            const msg = err.reason || err.message;

            const formatted = ERROR_MESSAGE_MAP[msg];

            toast.error(formatted || msg);
        }
        setLoading(false);
    };

    const buttons_disabled =
        more_than_available || more_than_allocated || !gt_zero || exceeds_cap;

    const start = dayjs(Number(event?.start_date) * 1000);

    const has_not_started = dayjs().isBefore(start);

    return (
        <div className="flex items-center space-x-4 mt-4">
            {account &&
                !isConnectedChainEventChain(event_chain_id) &&
                is_connected_verified_wallet && (
                    <Button
                        className="w-full"
                        onClick={async () => {
                            if (settingChain) return;
                            await setChainID(event_chain_id);
                        }}
                        disabled={settingChain}
                    >
                        {settingChain
                            ? 'Switching Networks'
                            : `Switch to ${NETWORKS[event_chain_id].network_name}`}
                    </Button>
                )}

            {account &&
                isConnectedChainEventChain(event_chain_id) &&
                is_connected_verified_wallet && (
                    <>
                        <Button
                            loading={approve_loading}
                            className="flex-1 w-full"
                            onClick={handleApprove}
                            disabled={
                                buttons_disabled || approved || approve_loading
                            }
                        >
                            1. Enable
                        </Button>
                        <Button
                            loading={loading}
                            className="flex-1 w-full"
                            onClick={handleInvest}
                            disabled={
                                buttons_disabled ||
                                !approved ||
                                loading ||
                                has_not_started
                            }
                        >
                            2. Fund
                        </Button>
                    </>
                )}

            {account && !is_connected_verified_wallet && (
                <Button
                    className="w-full"
                    onClick={() => connect()}
                    disabled={settingChain}
                >
                    Connect verified wallet
                </Button>
            )}

            {!account && (
                <Button
                    className="w-full"
                    onClick={() => connect()}
                    disabled={settingChain}
                >
                    Connect wallet
                </Button>
            )}
        </div>
    );
};
