import React from 'react';
import { FormData, KYCSteps } from '@decub8/ui';

import OnboardingHeader from '@src/components/OnboardingHeader';
import { ProtectedRoute } from '@src/components/ProtectedRoute';
import { kyc_form_data } from '@src/constants';

import { useKYCForm } from './hooks';

export const KYCForm: React.FC<{
    handleComplete: () => Promise<void>;
    onboarding?: boolean;
}> = ({ handleComplete, onboarding }) => {
    const {
        state,
        loading,
        handleChange,
        handleUpdate,
        handleSkipNow,
        is_dob_over_18,
        alert,
        has_eu_restriction,
        is_eu_country,
    } = useKYCForm({ onCompleteCallback: handleComplete });

    return (
        <ProtectedRoute>
            <OnboardingHeader current_step={2} />
            <KYCSteps
                className="mb-[100px]"
                show_skip={onboarding}
                handleSkipNow={handleSkipNow}
                handleClick={handleUpdate}
                loading={loading}
                disabled={
                    loading ||
                    !state['first_name'] ||
                    !state['last_name'] ||
                    !state['dob'] ||
                    !is_dob_over_18 ||
                    (has_eu_restriction && is_eu_country)
                }
                Kyc_form_data={kyc_form_data as FormData[]}
                state={state}
                handleChange={handleChange}
                error={
                    state.dob && !is_dob_over_18
                        ? 'You must be 18 or over to proceed.'
                        : undefined
                }
                // only show the eu restriction message if the wl client has the restriction
                alert={alert}
            />
        </ProtectedRoute>
    );
};
