import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { EventType } from '@src/ts/constants';
import { EventStage, ProjectEvent } from '@src/ts/interfaces';

import { CrowdfundingComplete } from './CrowdfundingComplete';
import { FCFS } from './FCFS';
import { GARound } from './GARound';
import LearnToEarn from './LearnToEarn';
import { Qualify } from './Qualify';
import { EventRegister } from './Register';
import { TokenClaimComplete } from './TokenClaimComplete';

dayjs.extend(utc);

export enum InvestmentRound {
    GA = 'GA',
    FCFS = 'FCFS',
    FCFS2 = 'FCFS2',
}

export const stages = {
    [EventType.Crowdfunding]: (
        project_id: string,
        event: ProjectEvent,
        payment_balance: string,
    ): EventStage[] => {
        const start =
            event?.start_date && dayjs(Number(event.start_date) * 1000);

        return [
            {
                name: 'Whitelist for this event',
                component: EventRegister,
                props: {
                    event,
                },
            },
            {
                name: 'Snapshot',
                component: null,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.GA,
                    payment_balance,
                },
            },
            {
                name: 'Guaranteed allocation',
                component: GARound,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.GA,
                    payment_balance,
                },
                date: event.start_date || 'TBA',
            },
            {
                name: 'FCFS round one',
                component: FCFS,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.FCFS,
                    payment_balance,
                },
                date:
                    (event.start_date &&
                        start
                            .add(event.event_details.durations[0], 'seconds')
                            .unix()
                            .toString()) ||
                    'TBA',
            },
            {
                name: 'FCFS round two',
                component: FCFS,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.FCFS2,
                    payment_balance,
                },
                date:
                    (event.start_date &&
                        start
                            .add(
                                event.event_details.durations[0] +
                                    event.event_details.durations[1],
                                'seconds',
                            )
                            .unix()
                            .toString()) ||
                    'TBA',
            },
            {
                name: 'Event complete',
                component: CrowdfundingComplete,
                date:
                    (event.start_date &&
                        start
                            .add(
                                event.event_details.durations.reduce(
                                    (a, b) => a + b,
                                    0,
                                ),
                                'seconds',
                            )
                            .unix()
                            .toString()) ||
                    'TBA',
            },
        ];
    },
    [EventType.TokenClaim]: (
        project_id: string,
        event: ProjectEvent,
        scrollToLearnToEarn: () => void,
    ): EventStage[] => [
        {
            name: 'Qualify for this event',
            component: Qualify,
            props: { event, min_tier: event.event_details.min_tier || 0 },
        },
        {
            name: 'Learn to earn',
            component: LearnToEarn,
            props: { event, scrollToLearnToEarn, project_id },
        },
        {
            name: 'Claim',
            component: TokenClaimComplete,
            date: event.start_date || 'TBA',
        },
    ],
};
