import { Input as InputUI } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';

import { ChangeEvent } from '@src/ts/types';
import { bnMin, parseBalance, removeCommas } from '@src/utils/web3';

import { useProjectContext } from '../context';

export const Input: React.FC<{
    disabled: boolean;
    total_raised: string;
    hardcap: string;
    user_allocation: string;
    user_investment: string;
    payment_amount: string;
    payment_symbol: string;
    payment_decimals: number;
    disable_max: boolean;
    second_round_cap?: string;
}> = ({
    disabled,
    total_raised = '0',
    hardcap = '0',
    user_allocation = '0',
    user_investment = '0',
    payment_amount = '0',
    payment_symbol,
    payment_decimals,
    disable_max,
    second_round_cap,
}) => {
    const {
        invest_amount = '0',
        setInvestAmount,
        ga_registered,
        user_allocation_wei,
    } = useProjectContext();

    const vals = [
        BigNumber.from(payment_amount),
        BigNumber.from(user_allocation).sub(
            user_allocation === '0'
                ? '0'
                : ga_registered
                ? user_investment
                : user_allocation_wei,
        ),
        BigNumber.from(hardcap).sub(total_raised),
    ];
    if (second_round_cap) vals.push(BigNumber.from(second_round_cap));

    const handleMaxClick = () => {
        if (disable_max) return;

        const min = bnMin(vals).toString();

        const parsed_balance = removeCommas(
            parseBalance(min, payment_decimals),
        );

        setInvestAmount(parsed_balance);
    };

    const handleChange = (e: ChangeEvent) => setInvestAmount(e.target.value);

    return (
        <>
            <InputUI
                disabled={disabled}
                placeholder="0.0"
                unit={payment_symbol}
                surface="zero"
                value={invest_amount}
                onChange={handleChange}
                handleMaxClick={handleMaxClick}
                type="number"
            />
        </>
    );
};
