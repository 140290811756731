import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, DataLines, Typography } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { ConnectedChain } from '@web3-onboard/core';
import { NextRouter, useRouter } from 'next/router';

import { DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { TOKEN_DECIMALS } from '@src/constants';
import { useAppSelector, useContract } from '@src/hooks';
import { useEventToken } from '@src/hooks/useEventToken';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { User } from '@src/ts/interfaces';
import { executeTx, parseBalance } from '@src/utils/web3';

import { useProjectContext } from '../context';

import { EventCompleteAlert } from './EventContainer/EventCompleteAlert';
import { useTokenClaimHasClaimed } from './hooks';

export const getTokenClaimCompleteButtonText = (
    finalized: boolean,
    account: string,
    is_connected_verified_wallet: boolean,
    has_claimed: boolean,
) => {
    if (!finalized) return 'Calculating allocation...';
    if (account && !is_connected_verified_wallet)
        return 'Connect verified walet';
    if (!account) {
        return 'Connect wallet';
    } else if (has_claimed) {
        return 'View in your portfolio';
    }
    return 'Accept tokens';
};

export const handleTokenCalimCompleteClick = async (
    account: string,
    connect: () => void,
    connectedChain: ConnectedChain,
    setChainID: (id: number) => Promise<boolean>,
    is_connected_verified_wallet: boolean,
    event_chain_id: number,
    has_claimed: boolean,
    router: NextRouter,
    user: User,
    uses_merkle_root: boolean,
    token_claim: Contract,
    setLoading: (loading: boolean) => void,
    alloc_to_use: BigNumber,
    proof: string[],
    update: () => Promise<void>,
) => {
    if (!account) {
        connect();
        return;
    }

    if (account && !is_connected_verified_wallet) {
        connect();
        return;
    }

    if (Number(connectedChain?.id) !== Number(event_chain_id)) {
        setChainID(event_chain_id);
        return;
    }

    if (has_claimed) {
        router.push(`/portfolio/${user?.wallet_address}`);
        return;
    }

    setLoading(true);

    try {
        if (uses_merkle_root) {
            await executeTx(
                token_claim.claimTokens(alloc_to_use, proof),
                'Success',
                update,
            );
        } else {
            await executeTx(token_claim.claimTokens(), 'Success', update);
        }
    } catch (err) {
        console.error(err);
    }

    setLoading(false);
};

export const TokenClaimComplete: React.FC = () => {
    const {
        event,
        user_allocation_wei,
        project: { token },
        proof,
        finalized,
        getWhitelistSummary,
        ga_registered: _registered,
    } = useProjectContext();

    const { decimals } = useEventToken(event);

    const { user } = useAppSelector((state) => state?.auth) || {};
    const router = useRouter();
    const { account, connect, connectedChain, setChainID, settingChain } =
        useWeb3Onboard();
    const [loading, setLoading] = useState(false);
    const { address, abi = '[]' } = event.contract || {};
    const ABI = useMemo(
        () =>
            JSON.parse(abi || '[]').filter(
                ({ name }) => name !== 'Initialized',
            ),
        [abi],
    );

    useEffect(() => {
        if (finalized) return;

        const handler = setInterval(getWhitelistSummary, 5000);

        return () => clearInterval(handler);
    }, [finalized, getWhitelistSummary]);

    const token_claim = useContract(address, ABI, true);

    const token_symbol = token?.ticker || '';

    const uses_merkle_root = !!token_claim?.setMerkleRoot;

    // get whether a user has claimed or not how we used to get the allocation before the event reg hook above
    const [has_claimed, legacy_alloc, update] = useTokenClaimHasClaimed(event);

    // make sure to use the correct value for allocation depending on whether it's a merkle root event or not
    const alloc_to_use = uses_merkle_root
        ? BigNumber.from(user_allocation_wei)
        : legacy_alloc;

    const event_chain_id = event.chainId || DEFAULT_CHAIN_ID;

    const is_connected_verified_wallet =
        user?.wallet_address?.toLowerCase() === account?.toLowerCase();

    const registered =
        (!has_claimed && _registered) || (user && !alloc_to_use.eq(0));

    return (
        <div className="">
            {DEFAULT_CHAIN_ID !== Number(event_chain_id) && user && (
                <Alert variant="note" className="mb-4">
                    <div className="flex items-center space-x-4">
                        <img
                            src={NETWORKS[event_chain_id]?.network_logo}
                            alt="network"
                        />
                        <div>
                            This event is on{' '}
                            <strong>
                                {NETWORKS[event_chain_id]?.network_name}
                            </strong>
                            . Please make sure you are connected to{' '}
                            <strong>
                                {NETWORKS[event_chain_id]?.network_name}
                            </strong>
                            .
                        </div>
                    </div>
                </Alert>
            )}

            {!registered && <EventCompleteAlert />}
            {registered && (
                <>
                    <Typography variant="secondary" size="sm" className="mb-4">
                        {has_claimed
                            ? 'You can view and claim your tokens in your investment portfolio'
                            : 'Thank you for participating in the event. Accept your tokens to view and claim them in your investment portfolio.'}
                    </Typography>{' '}
                    {has_claimed && (
                        <DataLines
                            className="my-4"
                            label={'Token allocation'}
                            values={[
                                {
                                    value: `${parseBalance(
                                        alloc_to_use,
                                        TOKEN_DECIMALS[token_symbol] ||
                                            decimals,
                                    )} ${token_symbol}`,
                                },
                            ]}
                        />
                    )}
                    <Button
                        loading={loading || settingChain}
                        disabled={loading || !finalized}
                        className="w-full py-3 mt-1"
                        onClick={() =>
                            handleTokenCalimCompleteClick(
                                account,
                                connect,
                                connectedChain,
                                setChainID,
                                is_connected_verified_wallet,
                                event_chain_id,
                                has_claimed,
                                router,
                                user,
                                uses_merkle_root,
                                token_claim,
                                setLoading,
                                alloc_to_use,
                                proof,
                                update,
                            )
                        }
                    >
                        {getTokenClaimCompleteButtonText(
                            finalized,
                            account,
                            is_connected_verified_wallet,
                            has_claimed,
                        )}
                    </Button>
                </>
            )}
        </div>
    );
};
