import React, { useEffect, useState } from 'react';
import { EventIcons, ImageSlideshow, Tabs, Typography } from '@decub8/ui';
import dayjs from 'dayjs';

import {
    BASE_IMAGE_URL,
    CONTENT,
    DEFAULT_CHAIN_ID,
    NETWORKS,
} from '@src/config';
import { EventType } from '@src/ts/constants';
import {
    ExchangeType,
    FAQ as FAQInterface,
    ProjectEvent,
    ProjectLinks,
    ProjectToken,
    SocialLink,
} from '@src/ts/interfaces';
import { ProjectItemProps } from '@src/ts/props';

import { EventContainer } from '../Event/EventContainer';
import { LearnToEarn } from '../LearnToEarn';

import FAQ from './FAQ';
import { ProjectDetails } from './ProjectDetails';
import TokenMetrics from './TokenMetrics';
import VestingDetails from './VestingDetails';

interface OverviewProps extends ProjectItemProps {
    event: ProjectEvent;
    should_display_event: boolean;
    scrollToLearnToEarn: () => void;
}

const renderTab = (
    tab_id: string,
    token: ProjectToken,
    event: ProjectEvent,
    description: string,
    explanation: string,
    faqs: FAQInterface[],
    links: ProjectLinks,
    name: string,
    social_platforms: SocialLink[],
    exchanges: ExchangeType[],
    whitepaper: string,
) => {
    switch (tab_id) {
        case 'details':
            return (
                <ProjectDetails
                    token={token}
                    event={event}
                    description={description}
                    explanation={explanation}
                    links={links}
                    social_platforms={social_platforms}
                    whitepaper={whitepaper}
                />
            );
        case 'vesting-details':
            return (
                <VestingDetails
                    token={token}
                    event={event}
                    exchanges={exchanges}
                />
            );
        case 'faqs':
            // Only render FAQs if there are FAQs available
            return faqs.length > 0 ? <FAQ faqs={faqs} name={name} /> : null;
        case 'token-metrics':
            return <TokenMetrics />;
        default:
            return null;
    }
};

const ProjectOverview = React.forwardRef<HTMLDivElement, OverviewProps>(
    ({ project, scrollToLearnToEarn, event, should_display_event }, ref) => {
        const {
            id,
            name,
            subtitle,
            description,
            explanation,
            social_platforms,
            token,
            links,
            faqs,
            hero,
            hero_images = [],
            exchanges,
            whitepaper,
        } = project || {};

        const has_refund_deadline =
            !!project?.events[0]?.refund_deadline &&
            project?.events[0]?.refund_deadline > 0;

        const tab_options = [
            { title: 'Project details', id: 'details' },
            { title: 'Token metrics', id: 'token-metrics' },
            { title: 'Vesting details', id: 'vesting-details' },
            // Only include the "FAQs" tab if there are FAQs
            ...(faqs?.length > 0 ? [{ title: 'FAQs', id: 'faqs' }] : []),
        ];

        const [nav, setNav] = useState(tab_options[0].id);

        useEffect(() => {
            const video = document.getElementById(
                'hero-video',
            ) as HTMLMediaElement;

            const listener = () => {
                // Check if the video is loaded
                if (video.readyState >= 3) {
                    video.controls = true;
                }
            };

            // Add the event listener
            video?.addEventListener('loadeddata', listener);

            // Cleanup function to remove the listener when the component unmounts
            return () => {
                if (video) {
                    video.removeEventListener('loadeddata', listener);
                }
            };
        }, []);

        const is_crowdfunding = event?.type === EventType.Crowdfunding;

        const learn_to_earn_event =
            event && event.type === EventType.TokenClaim && event;

        const not_over =
            learn_to_earn_event &&
            dayjs(Number(learn_to_earn_event.start_date) * 1000).isAfter(
                dayjs(),
            );

        // if only onehero image, don't display slideshow
        const slideshow_items = [
            ...(hero ? [{ image: `${BASE_IMAGE_URL}/${id}/${hero}` }] : []),
            ...(Array.isArray(hero_images)
                ? hero_images
                      .filter((img) => img && img.trim() !== '')
                      .map((img) => ({
                          image: `${BASE_IMAGE_URL}/${id}/${img}`,
                      }))
                : []),
        ];

        const event_chain_id = event?.chainId || DEFAULT_CHAIN_ID;

        return (
            <div
                className={`py-5 mb-[100px]  ${
                    should_display_event ? '' : 'max-w-[800px] mx-auto'
                }`}
            >
                <Typography allBold as={'h1'} size="3xl">
                    {name}
                </Typography>
                <Typography className="mt-[10px]" size="md" variant="secondary">
                    {subtitle}
                </Typography>

                {/* Icons section */}
                <div className="my-5">
                    <EventIcons
                        company_logo={CONTENT.logo.mobile}
                        company_name={CONTENT.companyName}
                        network_info={{
                            logo: NETWORKS[event_chain_id]?.network_logo,
                            name: NETWORKS[event_chain_id]?.network_name,
                        }}
                        protected_logo={
                            is_crowdfunding && has_refund_deadline
                                ? 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg'
                                : ''
                        }
                        project_type={project?.project_type}
                    />
                </div>

                <ImageSlideshow items={slideshow_items} className="mt-[30px]" />
                {should_display_event && (
                    <EventContainer
                        project_id={project.id}
                        scrollToLearnToEarn={scrollToLearnToEarn}
                        event={event}
                        mobile
                    />
                )}
                <Tabs
                    className="my-[60px]"
                    options={tab_options}
                    current={nav}
                    setCurrent={(id) => setNav(id)}
                    variant="limited"
                />
                {renderTab(
                    nav,
                    token,
                    event,
                    description,
                    explanation,
                    faqs,
                    links,
                    name,
                    social_platforms,
                    exchanges,
                    whitepaper,
                )}
                {learn_to_earn_event && not_over && (
                    <LearnToEarn
                        project={project}
                        event={learn_to_earn_event}
                        ref={ref}
                    />
                )}
            </div>
        );
    },
);

export default ProjectOverview;
