import { useContext, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useRouter } from 'next/router';

import { api_client, store } from '@src/bootstrap';
import { EU_COUNTRIES } from '@src/constants';
import { useAppSelector } from '@src/hooks';
import { GlobalContext, useGlobalContext } from '@src/hooks/useGlobalContext';
import { USER } from '@src/services';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { ChangeEvent } from '@src/ts/types';
import { getCountryCode } from '@src/utils/getters';

interface UseUpdateUserProps {
    // function to call after KYC form completion
    onCompleteCallback?: () => Promise<void>;
}

export const useKYCForm = ({ onCompleteCallback }: UseUpdateUserProps) => {
    // Get the authenticated user from the global state
    const { user } = useAppSelector((state) => state?.auth) || {};

    // State variables to manage form state and loading status
    const [triedSet, setTriedSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        dob: '',
    });

    const { eventRegistrationContext, has_eu_restriction } = useGlobalContext();

    // Access the global context for setting KYC completion status
    const { setShowKycCompleted } = useContext(GlobalContext);

    const now: Dayjs = dayjs();

    const router = useRouter();

    const is_dob_over_18 = now.diff(state.dob, 'year') >= 18;

    const users_country_code = getCountryCode(user?.country);

    const is_eu_country = users_country_code
        ? EU_COUNTRIES.has(users_country_code)
        : false;

    // Handle form field changes
    const handleChange = (e: ChangeEvent) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const { user } = store.getState().auth;
            const { first_name, last_name, dob } = state;

            if (
                first_name !== user?.first_name ||
                last_name !== user?.last_name ||
                dob !== user?.dob
            ) {
                // Update user data via API call if there are changes
                await api_client.mutate({
                    mutation: USER.UPDATE_USER,
                    variables: { id: user.id, first_name, last_name, dob },
                });
            }

            if (onCompleteCallback) await onCompleteCallback();
        } catch (err) {
            // Handle error if necessary
        } finally {
            setLoading(false);
        }
    };

    // Handle skipping the KYC process
    const handleSkipNow = () => {
        localStorage.setItem('kycSkipped', 'true');
        if (eventRegistrationContext) {
            router.push(
                `/project/${eventRegistrationContext.project_slug}/${eventRegistrationContext.event_slug}`,
            );
            return;
        }
        router.push('/account');
    };

    // Effect to initialize form state and handle verified users
    useEffect(() => {
        if (
            !triedSet &&
            user &&
            !state.first_name &&
            !state.last_name &&
            (!!user?.first_name || !!user?.last_name)
        ) {
            setTriedSet(true);
            setState({
                ...state,
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                dob: user?.dob || '',
            });
        }
        if (
            !eventRegistrationContext &&
            !onCompleteCallback &&
            user &&
            user.identity_verified === IdentityVerifiedStatus.VERIFIED
        ) {
            setShowKycCompleted(true);
            router.push('/account');
        }
    }, [user, onCompleteCallback, state, triedSet]);

    return {
        state,
        loading,
        handleChange,
        handleUpdate,
        handleSkipNow,
        is_dob_over_18,
        alert:
            is_eu_country && has_eu_restriction
                ? {
                      content: (
                          <span>
                              We are temporarily pausing new EU registrations
                              while we finalize our MiCAR license. We appreciate
                              your patience and remain committed to compliance.{' '}
                              <a
                                  target="_blank"
                                  className="text-warning hover:underline cursor-pointer"
                                  href={`https://www.decubate.com/blog/decubate-micar-ensuring-compliance-and-security-for-our-users`}
                              >
                                  Learn more here.
                              </a>
                          </span>
                      ),
                      variant: 'warning',
                      title: 'Important information',
                  }
                : undefined,
        has_eu_restriction,
        is_eu_country,
    };
};
