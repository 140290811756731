import { useContext } from 'react';
import { Button, DataLines, Typography } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useNativeTokenBalance } from '@src/hooks/useNativeTokenBalance';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { bnFormatter } from '@src/utils/format';
import { parseBalance, removeCommas } from '@src/utils/web3';

import { useProjectContext } from '../context';

import { InvestmentRound } from './constants';
import { InvestButtons } from './InvestButtons';
import { Input } from './InvestInput';
import { getRoundMultiplier } from './utils';

export const GARound: React.FC<{
    round: InvestmentRound;
    payment_balance: string;
}> = ({ round, payment_balance }) => {
    const { _userTier } = useContext(GlobalContext);
    const { user } = useAppSelector((state) => state?.auth) || {};
    const { account } = useWeb3Onboard();
    const {
        event,
        crowdfunding: { total_raised, hardcap, user_investment } = {
            total_raised: '0',
            hardcap: '0',
            user_investment: '0',
        },
        user_allocation_wei,

        invest_amount,
        ga_registered,
    } = useProjectContext();

    const start = dayjs(Number(event?.start_date) * 1000);
    const should_display_alloc = dayjs().isAfter(start);
    const event_chain_id = event.chainId || DEFAULT_CHAIN_ID;

    const { symbol: payment_symbol, decimals: payment_decimals } =
        CONTRACT.PaymentToken[event_chain_id];

    const { symbol: native_symbol, balance: native_balance } =
        useNativeTokenBalance(event_chain_id);

    const invest_amount_wei = parseUnits(
        removeCommas(invest_amount || '0'),
        payment_decimals,
    );

    const { isConnectedChainEventChain, setChainID, settingChain } =
        useSwitchChain();

    const is_connected_verified_wallet =
        user?.wallet_address?.toLowerCase() === account?.toLowerCase();

    const multiplier = getRoundMultiplier(round);

    const fcfs_multiplier = getRoundMultiplier(InvestmentRound.FCFS);

    const fcfs_allocation = BigNumber.from(user_allocation_wei)
        .mul(fcfs_multiplier)
        .sub(user_allocation_wei);

    const round_allocation =
        BigNumber.from(user_allocation_wei).mul(multiplier);

    const more_than_allocated = invest_amount_wei.gt(
        round_allocation.sub(user_investment),
    );

    const more_than_available = invest_amount_wei.gt(
        BigNumber.from(payment_balance),
    );

    const has_invested = BigNumber.from(user_investment).gt(0);
    const alloc_loaded = _userTier !== null;

    const event_network = NETWORKS[event_chain_id];

    const used_all_of_allocation =
        round_allocation.gt(0) && round_allocation.eq(user_investment);

    if (!user) return;

    if (!ga_registered) return;

    if (used_all_of_allocation && ga_registered) {
        return (
            <div className="space-y-4">
                <DataLines
                    label={'Total invested'}
                    values={[
                        {
                            value: `${parseBalance(
                                user_investment || 0,
                                payment_decimals,
                            )} ${payment_symbol}`,
                        },
                    ]}
                    loaded={alloc_loaded}
                />

                <DataLines
                    label={`FCFS max allocation`}
                    values={[
                        {
                            value: should_display_alloc
                                ? `${parseBalance(
                                      fcfs_allocation,
                                      payment_decimals,
                                  )} ${payment_symbol}`
                                : 'TBA',
                        },
                    ]}
                    loaded={alloc_loaded}
                />
            </div>
        );
    }

    if (
        !isConnectedChainEventChain &&
        user &&
        account &&
        is_connected_verified_wallet
    )
        return (
            <Button
                className="w-full"
                onClick={async () => {
                    if (settingChain) return;
                    await setChainID(event_chain_id);
                }}
                disabled={settingChain}
            >
                {settingChain
                    ? 'Switching Networks'
                    : `Switch to ${NETWORKS[event_chain_id].network_name}`}
            </Button>
        );

    // TODO: use the correct loadings
    return (
        <div className="">
            <div className="space-y-1">
                <Typography size="sm" variant="secondary">
                    {`Your wallet balance on ${event_network.network_name}`}
                </Typography>
                <DataLines
                    label={`$${payment_symbol}`}
                    values={[
                        {
                            value: `${bnFormatter(
                                payment_balance || 0,
                                payment_decimals,
                            )} ${payment_symbol}`,
                            color: more_than_available ? 'text-error' : '',
                        },
                    ]}
                    loaded={alloc_loaded}
                />
                <DataLines
                    label={`$${event_network.symbol}`}
                    values={[
                        {
                            value: `${Number(
                                formatUnits(
                                    native_balance || 0,
                                    18, // assuming here that any native token will have 18 decimals
                                ),
                            ).toFixed(3)} ${native_symbol}`,
                            color: more_than_available ? 'text-error' : '',
                        },
                    ]}
                    loaded={alloc_loaded}
                />
            </div>
            <div className="bg-surface-level-three h-[1px] w-full my-5"></div>
            <DataLines
                className="mb-[10px]"
                label={`${
                    has_invested
                        ? 'Remaining'
                        : round === InvestmentRound.GA
                        ? 'GA max'
                        : 'Your'
                }  allocation`}
                values={[
                    {
                        value: should_display_alloc
                            ? `${parseBalance(
                                  round_allocation.sub(user_investment),
                                  payment_decimals,
                              )} ${payment_symbol}`
                            : 'TBA',
                        color: more_than_allocated ? 'text-error' : '',
                    },
                ]}
                loaded={alloc_loaded}
            />
            <Typography variant="secondary" className="mb-[10px]" size="sm">
                {'Enter the amount you would like to invest'}
            </Typography>
            <Input
                disabled={!should_display_alloc}
                total_raised={total_raised}
                hardcap={hardcap}
                user_allocation={round_allocation.toString()}
                user_investment={user_investment}
                payment_amount={payment_balance}
                payment_decimals={payment_decimals}
                payment_symbol={payment_symbol}
                disable_max={!should_display_alloc}
            />
            <div className="my-4 space-y-1 text-lg">
                <DataLines
                    label={'Total invested'}
                    values={[
                        {
                            value: `${parseBalance(
                                user_investment || 0,
                                payment_decimals,
                            )} ${payment_symbol}`,
                        },
                    ]}
                    loaded={alloc_loaded}
                />
            </div>

            <InvestButtons
                invest_amount={invest_amount_wei.toString()}
                more_than_allocated={more_than_allocated}
                more_than_available={more_than_available}
            />
        </div>
    );
};
