import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { IContractManager } from '@src/contracts/manager';
import { useAppSelector } from '@src/hooks';
import { useEventDates } from '@src/hooks/useEventTimes';
import { GlobalContext, useGlobalContext } from '@src/hooks/useGlobalContext';
import {
    ContractType,
    EventType,
    IdentityVerifiedStatus,
} from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../context';

import { getTokenClaimAlloc, isHardcapMet } from './utils';

export const useCurrentEventStage = (min_tier: number): number => {
    const { _userTier } = useContext(GlobalContext);
    const { user } = useAppSelector((state) => state?.auth) || {};
    const { wallet_verified } = useAppSelector((state) => state?.auth) || {};
    const { ga_registered, crowdfunding, event } = useProjectContext();

    const { start_date, chainId, event_details } = event;

    const start = dayjs(Number(start_date) * 1000);

    const {
        has_started,
        is_fcfs_1,
        is_fcfs_2,
        is_event_end_date_has_passed,
        is_now_cutoff_period,
    } = useEventDates(start, event_details?.durations, event.type);

    const not_eligble =
        user?.identity_verified !== IdentityVerifiedStatus.VERIFIED ||
        !wallet_verified ||
        !_userTier ||
        !_userTier.flag ||
        _userTier.id < min_tier;

    const stage_event_functions = {
        [EventType.Crowdfunding]: () => {
            const has_date = !!start_date;
            const payment_token =
                CONTRACT.PaymentToken[chainId || DEFAULT_CHAIN_ID];

            const { total_raised = '0', hardcap = '0' } = crowdfunding || {};

            const hardcap_met = isHardcapMet(
                payment_token.decimals,
                hardcap,
                total_raised,
            );

            const is_over = hardcap_met || is_event_end_date_has_passed;

            const _not_eligble = not_eligble || !ga_registered;

            if (
                (!has_date || (!is_over && _not_eligble) || !has_started) &&
                !is_now_cutoff_period &&
                !has_started
            )
                return 0;
            else if (is_now_cutoff_period) {
                return 1;
            } else if (is_fcfs_1) {
                return 3;
            } else if (is_fcfs_2) {
                return 4;
            } else if (is_over) {
                return 5;
            }
            return 2;
        },
        [EventType.TokenClaim]: () => {
            if (!is_event_end_date_has_passed && not_eligble) return 0;
            if (is_event_end_date_has_passed) return 2;
            return 1;
        },
    };

    return stage_event_functions[event.type]();
};

const getInvestmentsContractType = (
    event: ProjectEvent,
    contract_manager: IContractManager,
): { type: ContractType; is_legacy: boolean } => {
    const is_legacy = Number(event.start_date || '0') < 1679270400;

    if (is_legacy) return { type: ContractType.Investments, is_legacy };

    if (!event.contract?.address) {
        console.error('Contract address is missing');
        return { type: ContractType.EventFactory, is_legacy };
    }

    try {
        const event_contract = event.contract;
        const crowdfunding = contract_manager.getContractByAddressAndABI(
            event_contract.address,
            event_contract.abi,
        );

        if (crowdfunding.contract.setMerkleRoot) {
            return { type: ContractType.EventFactory, is_legacy };
        }

        return { type: ContractType.LegacyEventFactory, is_legacy };
    } catch (error) {
        console.error('Error getting contract:', error);
        return { type: ContractType.EventFactory, is_legacy }; // fallback to EventFactory on error
    }
};

export const useTokenClaimHasClaimed = (
    event: ProjectEvent,
): [boolean, BigNumber, () => Promise<void>] => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const [has_claimed, setHasClaimed] = useState(true);
    const [allocation, setLegacyAllocation] = useState(BigNumber.from(0));
    const { contract_manager } = useGlobalContext();
    const { is_legacy, type } = useMemo(
        () => getInvestmentsContractType(event, contract_manager),
        [event, contract_manager],
    );
    const { contract } = event;

    const updateState = useCallback(({ has_claimed, allocation }) => {
        setHasClaimed(has_claimed);
        setLegacyAllocation(allocation);
    }, []);

    const { ga_registered } = useProjectContext();

    useEffect(() => {
        if (event?.type !== EventType.TokenClaim || !user) return;

        getTokenClaimAlloc(
            user,
            contract,
            is_legacy,
            type,
            event.chainId || DEFAULT_CHAIN_ID,
            ga_registered,
            contract_manager,
        )
            .then(updateState)
            .catch((err) =>
                log.error('error getting the token claim allocation', err),
            );
    }, [
        user,
        contract,
        is_legacy,
        type,
        event,
        ga_registered,
        contract_manager,
        updateState,
    ]);

    const updateAllocation = useCallback(
        () =>
            getTokenClaimAlloc(
                user,
                contract,
                is_legacy,
                type,
                event.chainId || DEFAULT_CHAIN_ID,
                ga_registered,
                contract_manager,
            )
                .then(updateState)
                .catch((err) => {
                    log.error('error getting the token claim allocation', err);
                }),
        [
            updateState,
            user,
            contract,
            is_legacy,
            type,
            event.chainId,
            ga_registered,
            contract_manager,
        ],
    );

    return [has_claimed, allocation, updateAllocation];
};
