import { useCallback, useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { api_client } from '@src/bootstrap/index';
import { DEFAULT_CHAIN_ID } from '@src/config';
import { useAppSelector } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
// import { mockedContext } from '@src/mock-event-flow/context';
// import { EventStageForMock } from '@src/mock-event-flow/project';
import { PROJECT } from '@src/services/project';
import { EventType } from '@src/ts/constants';
import {
    Crowdfunding,
    Project,
    ProjectEvent,
    UserWhitelistSummary,
} from '@src/ts/interfaces';

import { getCrowdfundingInfo } from './util';

interface WhitelistSummaryState {
    ga_registered: boolean;
    user_allocation: number;
    finalized: boolean;
    user_allocation_wei: string;
    num_whitelisted: number;
    proof: string[];
    fcfs_registered: boolean;
}

interface IProjectContext extends WhitelistSummaryState {
    project: Project;
    event: ProjectEvent;
    registerForWhitelist: (
        event_id: number,
        event_answer: string[],
    ) => Promise<void>;
    data_loading: boolean;
    whitelist_loading: boolean;
    getWhitelistSummary: () => Promise<void>;
    // state for the input field for investing in a crowdfunding event
    invest_amount: string;
    setInvestAmount: (amount: string) => void;
    updateCrowdfundingDetails: () => void;
    crowdfunding?: Crowdfunding;
}

const initial_whitelist_summary = {
    ga_registered: false,
    user_allocation: 0,
    finalized: false,
    fcfs_registered: false,
    user_allocation_wei: '0',
    num_whitelisted: 0,
    proof: [''],
};

// pass in event, project
const ProjectContext = createContext<IProjectContext>(null);

// const mocked_context = mockedContext(EventStageForMock.GARoundOpen);

export const ProjectContextProvider: React.FC<{
    project: Project;
    event?: ProjectEvent;
    children?: React.ReactNode;
}> = ({ children, event, project }) => {
    const [data_loading, setDataLoading] = useState(false);
    const [whitelist_loading, setWhitelistLoading] = useState(false);
    const [whitelist_summary, setWhitelistSummary] =
        useState<WhitelistSummaryState>(initial_whitelist_summary);
    const [invest_amount, setInvestAmount] = useState('0');
    const { contract_manager } = useGlobalContext();
    const { user } = useAppSelector((state) => state.auth);

    const { chainId = DEFAULT_CHAIN_ID, contract, type } = event || {};
    const is_legacy_event = !JSON.parse(event?.contract?.abi || '[]').find(
        ({ name }) => name === 'setMerkleRoot',
    );

    const [crowdfunding_state, setCrowdfundingState] = useState<Crowdfunding>();
    const { user_investment = '0' } = crowdfunding_state || {};

    const getWhitelistSummary = useCallback(async () => {
        try {
            const { getWhitelistSummary: whitelist_summary } =
                await api_client.query<{
                    getWhitelistSummary: UserWhitelistSummary;
                }>({
                    query: PROJECT.GET_WHITELIST_SUMMARY,
                    variables: {
                        event_id: event?.id,
                    },
                    fetchPolicy: 'network-only',
                });

            if (!whitelist_summary) return;

            const summary = {
                ga_registered: whitelist_summary.ga_registered,
                user_allocation: whitelist_summary.user_allocation?.allocation,
                finalized: whitelist_summary.finalized,
                user_allocation_wei:
                    whitelist_summary.user_allocation?.allocation_wei,
                num_whitelisted: whitelist_summary.num_whitelisted,
                proof: whitelist_summary.user_allocation?.proof,
                fcfs_registered: whitelist_summary.fcfs_registered,
            };

            setWhitelistSummary(summary);
        } catch (err) {
            console.error(`Error while fetching whitelist summary`, err);
        }
        setDataLoading(false);
    }, [event?.id]);

    // Use effect for getting initial data
    useEffect(() => {
        if (is_legacy_event) return;

        setDataLoading(true);

        getWhitelistSummary();
    }, [getWhitelistSummary, user, is_legacy_event]);
    // Get data for legacy events token claim events

    useEffect(() => {
        if (!is_legacy_event) return;
        setWhitelistSummary({ ...initial_whitelist_summary, finalized: true });
    }, [is_legacy_event]);

    // use effect for getting initial data for crowdfunding events
    useEffect(() => {
        if (type !== EventType.Crowdfunding) return;
        getCrowdfundingInfo(
            contract,
            contract_manager,
            chainId,
            event.id,
            user,
            crowdfunding_state?.user_investment,
        ).then(setCrowdfundingState);

        let handler;
        if (
            event &&
            event.type === EventType.Crowdfunding &&
            dayjs().isBefore(
                dayjs(Number(event.start_date) * 1000).add(
                    event.event_details.durations.reduce((a, b) => a + b, 0),
                    'seconds',
                ),
            ) &&
            event.contract?.abi &&
            event.contract?.address
        ) {
            handler = setInterval(
                () =>
                    getCrowdfundingInfo(
                        contract,
                        contract_manager,
                        chainId,
                        event.id,
                        user,
                        user_investment,
                    ).then(setCrowdfundingState),
                5000,
            );
        }
        return () => {
            if (handler) {
                clearInterval(handler);
                handler = null;
            }
        };
    }, [type, event?.id, contract, contract_manager, chainId, user]);

    const registerForWhitelist = async (
        event_id: number,
        event_answer: string[],
    ) => {
        setWhitelistLoading(true);
        try {
            await api_client.mutate({
                mutation: PROJECT.REGISTER_FOR_WHITELIST,
                variables: {
                    event_id,
                    event_answer,
                },
            });
            setWhitelistSummary({ ...whitelist_summary, ga_registered: true });
            toast.success('Successfully registered for the event');
        } catch (err) {
            toast.error(err.data?.message || err.reason || err.message);
        }
        setWhitelistLoading(false);
    };

    return (
        <ProjectContext.Provider
            value={{
                project,
                event,
                registerForWhitelist,
                ga_registered: whitelist_summary?.ga_registered,
                // this will only be true or false when snapshot is finalized
                fcfs_registered: whitelist_summary?.fcfs_registered,
                data_loading,
                whitelist_loading,
                user_allocation: whitelist_summary?.user_allocation || 0,
                finalized: whitelist_summary?.finalized,
                user_allocation_wei:
                    whitelist_summary?.user_allocation_wei || '0',
                num_whitelisted:
                    crowdfunding_state?.num_whitelisted || // to support legacy num whitelisted for crowdfunding events
                    whitelist_summary?.num_whitelisted ||
                    0,
                proof: whitelist_summary?.proof || [],
                getWhitelistSummary,
                invest_amount,
                setInvestAmount,
                crowdfunding: crowdfunding_state,
                updateCrowdfundingDetails: () =>
                    getCrowdfundingInfo(
                        contract,
                        contract_manager,
                        chainId,
                        event.id,
                        user,
                        crowdfunding_state.user_investment,
                    ).then(setCrowdfundingState),
            }}
            // value={mocked_context}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export function useProjectContext(): IProjectContext {
    return useContext(ProjectContext);
}
